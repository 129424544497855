<template>
    <conta-evento-form acao="ATUALIZAR" @salvar="atualizar($event)" :cancelar="cancelar" :errosPai="erros" :contaEvento="contaEvento"></conta-evento-form>
</template>

<script>
import ContaEventoForm from './ContaEventoForm.vue';
import ContasEventosServices from './services';

export default {
    components: { ContaEventoForm },

    data() {
        return {
            erros: [],
            contaEvento: null,
        };
    },
    methods: {
        cancelar() {
            this.$router.push({
                name: 'Checkins_ContasEventos',
            });
        },

        obterContaEvento() {
            this.$store.dispatch('addRequest');
            ContasEventosServices.obterContaPorId(this.$route.params.id)
                .then((response) => {
                    if (response?.success) {
                        this.contaEvento = response.data;
                    } else {
                        this.contaEvento = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        atualizar(contaEventoAtualizada) {
            this.$store.dispatch('addRequest');
            contaEventoAtualizada.contaEventoId = this.$route.params.id;
            ContasEventosServices.atualizar(contaEventoAtualizada)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Contas de Eventos',
                            detail: 'Conta de Evento atualizada com sucesso',
                            life: 3000,
                        });
                        this.cancelar();
                        this.$store.dispatch('setAtualizar', true);
                    } else {
                        this.erros = response.errors;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },

    mounted() {
        this.obterContaEvento();
    },
};
</script>
