import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKINS_PORT}${process.env.VUE_APP_API_PATH}/checkins`;

export default {
    obterTodas() {
        return axiosJwt.get(`${api}/contas-eventos`);
    },

    obterContaPorId(id) {
        return axiosJwt.get(`${api}/contas-eventos/${id}`)
    },

    inserir(data) {
        return axiosJwt.post(`${api}/contas-eventos/inserir`, data);
    },

    atualizar(data) {
        return axiosJwt.post(`${api}/contas-eventos/atualizar`, data);
    },

    excluir(id) {
        return axiosJwt.delete(`${api}/contas-eventos/${id}`)
    },

    pesquisarToken(token, tipo) {
        return axiosJwt.get(`${api}/contas-eventos/token/${tipo}/${token}`);
    },
};
