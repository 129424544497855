<template>
    <painel titulo="Conta de Evento" icone="pi pi-key" :mostrarRefresh="false">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-5">
                <label class="required">Conta</label>
                <InputText type="text" v-model="nome" @input="v$.nome.$touch()" maxlength="30" />
                <small class="p-error" v-if="v$.nome.$error">O nome da conta é obrigatório</small>
            </div>
            <div class="field col-12 md:col-2">
                <label class="required">Tipo</label>
                <Dropdown v-model="tipo" :options="tipos" optionLabel="text" optionValue="value" />
            </div>
            <div class="field col-12 md:col-5">
                <label class="required">Token</label>
                <div class="p-inputgroup">
                    <Button
                        icon="pi pi-search"
                        @click="pesquisarToken()"
                        label="Verificar Token"
                        title="Clique para verificar o token"
                        :disabled="!token || v$.tokenDigitadoOk.$invalid"
                        v-if="!tokenValido"
                    />
                    <Button icon="pi pi-refresh" @click="tokenValido = false" title="Modificar Token" :disabled="!token" v-if="tokenValido" />
                    <InputText type="text" v-model="token" @input="v$.token.$touch()" maxlength="200" :disabled="tokenValido" />
                </div>
                <small class="p-error" v-if="v$.token.$error || (v$.token.$dirty && v$.tokenDigitadoOk.$invalid)">O token da conta é obrigatório</small>
            </div>
            <div class="field col-12 md:col-6">
                <label class="required">Responsável</label>
                <InputText type="text" v-model="responsavel" @input="v$.responsavel.$touch()" maxlength="100" />
                <small class="p-error" v-if="v$.responsavel.$error">O responsável é obrigatório</small>
            </div>
            <div class="field col-12 md:col-6">
                <label class="required">E-mail</label>
                <InputText type="text" v-model="email" @input="v$.email.$touch()" maxlength="255" />
                <small class="p-error" v-if="v$.email.$error">O e-mail do responsável é obrigatório</small>
            </div>
        </div>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import { required, email } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ContasEventosServices from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        cancelar: {
            type: Function,
        },

        contaEvento: {
            type: Object,
        },

        errosPai: {
            type: Array,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            nome: null,
            token: null,
            responsavel: null,
            email: null,
            erros: [],
            tokenValido: false,
            tipo: 0,
            tipos: [
                { text: 'SYMPLA', value: 0 },
                { text: 'JALAN', value: 1 },
            ],
        };
    },

    validations() {
        return {
            nome: { required },
            token: { required },
            tokenDigitadoOk: () => {
                return this.token?.apenasLetrasNumeros() != '';
            },
            responsavel: { required },
            email: { required, email },
            tokenValido: () => {
                return this.tokenValido;
            },
        };
    },

    methods: {
        preencher() {
            this.nome = this.contaEvento.nome;
            this.token = this.contaEvento.token;
            this.responsavel = this.contaEvento.responsavel;
            this.email = this.contaEvento.email;
            this.tipo = this.contaEvento.tipo;
            this.tokenValido = true;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} a conta de evento?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let contaEventoDto = {
                nome: this.nome,
                token: this.token,
                responsavel: this.responsavel,
                email: this.email,
                tipo: this.tipo,
            };
            this.$emit('salvar', contaEventoDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },

        pesquisarToken() {
            this.$store.dispatch('addRequest');
            ContasEventosServices.pesquisarToken(this.token.apenasLetrasNumeros(), this.tipo)
                .then((response) => {
                    if (response?.success) {
                        this.tokenValido = true;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Contas Eventos',
                            detail: 'Token validado com sucesso',
                            life: 3000,
                        });
                    } else {
                        this.tokenValido = false;
                        this.erros = response.errors;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },

    watch: {
        contaEvento() {
            this.preencher();
        },

        tipo() {
            this.token = null;
            this.tokenValido = false;
        },

        errosPai() {
            this.erros = this.errosPai;
        },
    },
};
</script>
